var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"document-item"},[_vm._m(0),_c('PrismEditorCode',{attrs:{"code":_vm.code,"demo":_vm.demo}}),_c('br'),_c('h3',[_vm._v("注：IF_CODE与WAY_CODE的查看方式")]),_c('el-image',{staticClass:"image",attrs:{"src":require('@/assets/images/guide-7.png'),"preview-src-list":[require('@/assets/images/guide-7.png')]}}),_c('p'),_c('p',[_vm._v("支付宝对应的渠道代码IF_CODE为 ALI_PAY,该渠道的支付方式代码WAY_CODE有 H5、JSAPI等 ")]),_c('p',[_vm._v("微信对应的渠道代码IF_CODE为 WECHAT_PAY,该渠道的支付方式代码WAY_CODE有 H5、JSAPI等 ")])],1),_c('div',{staticClass:"document-item"},[_vm._m(1),_c('PrismEditorCode',{attrs:{"code":_vm.code2,"demo":_vm.demo}})],1),_c('div',{staticClass:"document-item"},[_vm._m(2),_c('PrismEditorCode',{attrs:{"code":_vm.code3,"demo":_vm.demo}})],1),_c('div',{staticClass:"document-item"},[_vm._m(3),_c('PrismEditorCode',{attrs:{"code":_vm.code4,"demo":_vm.demo}})],1),_c('div',{staticClass:"document-item"},[_vm._m(4),_c('PrismEditorCode',{attrs:{"code":_vm.code5,"demo":_vm.demo}})],1),_c('div',{staticClass:"document-item"},[_vm._m(5),_c('PrismEditorCode',{attrs:{"code":_vm.code6,"demo":_vm.demo}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("1、支付调用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("2、获取动态支付码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("3、支付回调")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("4、订单退款")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("5、订单查询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"h2-weight"},[_vm._v("6、退款查询")])])
}]

export { render, staticRenderFns }