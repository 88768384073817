<template>
    <div>
      <div class="document-item">
        <h2><span class="h2-weight">1、支付调用</span></h2>
        <PrismEditorCode :code="code" :demo="demo"></PrismEditorCode>
        <br>
        <h3>注：IF_CODE与WAY_CODE的查看方式</h3>
        <el-image class="image" :src="require('@/assets/images/guide-7.png')"
          :preview-src-list="[require('@/assets/images/guide-7.png')]">
        </el-image>
        <p></p>
        <p>支付宝对应的渠道代码IF_CODE为 ALI_PAY,该渠道的支付方式代码WAY_CODE有 H5、JSAPI等 </p>
        <p>微信对应的渠道代码IF_CODE为 WECHAT_PAY,该渠道的支付方式代码WAY_CODE有 H5、JSAPI等 </p>
      </div>
      <div class="document-item">
        <h2><span class="h2-weight">2、获取动态支付码</span></h2>
        <PrismEditorCode :code="code2" :demo="demo"></PrismEditorCode>
      </div>
      <div class="document-item">
        <h2><span class="h2-weight">3、支付回调</span></h2>
        <PrismEditorCode :code="code3" :demo="demo"></PrismEditorCode>
      </div>
      <div class="document-item">
        <h2><span class="h2-weight">4、订单退款</span></h2>
        <PrismEditorCode :code="code4" :demo="demo"></PrismEditorCode>
      </div>
      <div class="document-item">
        <h2><span class="h2-weight">5、订单查询</span></h2>
        <PrismEditorCode :code="code5" :demo="demo"></PrismEditorCode>
      </div>
      <div class="document-item">
        <h2><span class="h2-weight">6、退款查询</span></h2>
        <PrismEditorCode :code="code6" :demo="demo"></PrismEditorCode>
      </div>
    </div>
  </template>
  
  <script>
  import PrismEditorCode from '@/components/PrismEditorCode/index.vue'
  
  export default {
    name: 'sampleCode',
    components: {
      PrismEditorCode,
    },
  
    data() {
      return {
        code:
          `    /**
       * 聚合支付
       */
      @GetMapping("/pay")
      public void pay(HttpServletResponse httpServletResponse) throws IOException {
          Car car = new Car();
          car.setName("迈巴赫");
          car.setDescription("梅赛德斯奔驰。");
          car.setColor("BLACK");
          car.setProductionLocation("CHINA");
          String orderNo = IdUtil.getSnowflakeNextIdStr();
          log.info("订单号为:{}", orderNo);
          PayInvoke payInvoke = PayInvoke.builder()
                  .orderNo(orderNo)
                  .ifCode(IF_CODE)
                  .wayCode(WAY_CODE)
                  .currency("CNY")
                  .openId("o_djN5ZkF39FBo-IfrQHoEo7tq84")
                  .amount(1L)
                  .orderDetail(car)
                  .websocketState(0)
                  .notifyUrl("http://8.140.246.200:8103/api/mix/notify")
                  .build();
          InvokeResponse<String> pay = mixPayManager.pay(payInvoke, httpServletResponse);
          log.info("支付结果为:{}", pay);
      }`,
        code2:
          `    /**
       * 获取动态支付码
       */
      @GetMapping("/getPayQrCode")
      public void getPayQrCode() throws IOException {
          Car car = new Car();
          car.setName("迈巴赫");
          car.setDescription("梅赛德斯奔驰。");
          car.setColor("BLACK");
          car.setProductionLocation("CHINA");
          String orderNo = IdUtil.getSnowflakeNextIdStr();
          log.info("订单号为:{}", orderNo);
          QrCodeInvoke qrCodeInvoke = QrCodeInvoke.builder()
                  .orderNo(orderNo)
                  .currency("CNY")
                  .openId("o_djN5ZkF39FBo-IfrQHoEo7tq84")
                  .amount(1L)
                  .orderDetail(car)
                  .websocketState(0)
                  .notifyUrl("http://:8103/api/mix/notify")
                  .build();
          byte[] payQrCodeBytes = mixPayManager.getPayQrCode(qrCodeInvoke);
          log.info("二维码图片流信息{}",payQrCodeBytes);
      }`,
        code3:
          `    /**
       * 接收订单的支付回调通知
       */
      @PostMapping("/notify")
      public void handlePayNotify(@RequestBody PayNotifyResp payNotifyResp) {
          log.info("支付回调通知:{}", payNotifyResp);
      }`,
        code4:
          `    /**
       * 订单退款
       */
      @GetMapping("/refund/{orderNo}")
      public void refund(@PathVariable("orderNo") String orderNo) {
          String refundOrderNo = IdUtil.getSnowflakeNextIdStr();
          RefundInvoke refundInvoke = RefundInvoke.builder()
                  .orderNo(orderNo).refundAmount(1L).payAmount(1L)
                  .refundReason("测试退款")
                  .refundOrderNo(refundOrderNo).currency("CNY")
                  .notifyUrl("http://8.140.246.200:8103/api/mix/refundNotify").build();
          InvokeResponse<RefundResp> orderRefundResp = mixPayManager.refundOrder(refundInvoke);
          log.info("订单退款结果为:{}", orderRefundResp);
      }`,
        code5:
          `    /**
       * 查询订单支付状态
       */
      @GetMapping("/queryOrderStatus/{orderNo}")
      public void queryOrderStatus(@PathVariable("orderNo") String orderNo) {
          QueryOrderInvoke queryOrderInvoke = QueryOrderInvoke.builder().orderNo(orderNo).build();
          InvokeResponse<QueryOrderResp> queryOrderRespInvokeResponse = mixPayManager.queryOrderStatus(queryOrderInvoke);
          log.info("订单状态为:{}", queryOrderRespInvokeResponse);
      }`,
        code6:
          `    /**
       * 退款状态查询
       */
      @GetMapping("/queryRefundStatus/{orderNo}/{refundNo}")
      public void queryRefundStatus(@PathVariable("orderNo") String orderNo, @PathVariable("refundNo") String refundNo) {
          QueryRefundInvoke queryRefundInvoke = QueryRefundInvoke.builder().refundOrderNo(refundNo).orderNo(orderNo).build();
          InvokeResponse<QueryRefundResp> queryRefundResp = mixPayManager.queryRefundOrderStatus(queryRefundInvoke);
          log.info("退款状态为:{}", queryRefundResp);
      }`,
        demo: `<dependency>`
      };
    },
  
    beforeCreate() {
      console.log("父组件：beforeCreate", this.demo)
    },
  
    created() {
      console.log("父组件：created", this.demo)
    },
  
    beforeMount() {
      console.log("父组件：beforeMount", this.demo)
    },
  
    mounted() {
      console.log("父组件：mounted", this.demo)
    },
  
    methods: {},
  }
  </script>
  
  <style scoped lang="less">
  // 每一块文档
  .document-item {
    margin-bottom: 50px;
  
    .h2-weight {
      font-size: 24px;
      font-weight: 600;
    }
  
    p {
      font-family: Hiragino Sans GB;
    }
  
    .image {
      width: 40%;
    }
  }
  </style>